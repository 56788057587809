<template>
  <div>
    <ViewTemplateWithTable :panel="panel" :templateList="templateList">
      <div slot="content-buttons"></div>
    </ViewTemplateWithTable>
    <PrintPdv v-if="print" :rentId="rentId" :onHideModal="onHideModal" />
  </div>
</template>

<script>
import ViewTemplateWithTable from "@nixweb/nixloc-ui/src/component/template/ViewTemplateWithTable.vue";

import PrintPdv from "../../../components/modules/pdv/rent/PrintPdv.vue";

import { mapGetters, mapMutations } from "vuex";

export default {
  name: "PdvRentListView",
  components: {
    ViewTemplateWithTable,
    PrintPdv,
  },
  data() {
    return {
      rentId: "",
      print: false,
      panel: {
        module: "PDV",
        title: "Locação",
        showFilter: true,
        showSearch: true,
        showButtons: false,
      },
      templateList: {
        urlGetApi: "/api/v1/rental/rent/get-all",
        urlDeleteAllApi: "/api/v1/rental/rent/delete",
        showChecks: false,
        headerTable: [
          {
            title: "Número/Data",
            field: "numberAndDate",
            type: "text",
            iconSearch: true,
          },
          {
            field: "fullName",
            title: "Nome",
            type: "text",
            iconSearch: true,
          },
          {
            field: "consultant",
            title: "Consultor",
            type: "text",
            iconSearch: true,
          },
          {
            title: "Valor",
            field: "value",
            classCssTitle: "text-center",
            classCssBody: "text-center",
            type: "currency",
          },
          {
            type: "button",
            classCssBody: "text-center",
            typeButton: "default",
            button: {
              classIcon: "fa-sharp fa-regular fa-share-nodes",
              type: "info",
              size: "small",
              eventName: "printRentPdv",
            },
          },
        ],
      },
    };
  },
  mounted() {
    this.removeLoading(["btnPdvRentList"]);
  },
  computed: {
    ...mapGetters("generic", ["event"]),
  },
  methods: {
    ...mapMutations("generic", ["removeLoading"]),
    onHideModal() {
      this.print = false;
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "printRentPdv") {
          this.rentId = event.data.id;
          this.print = true;
        }
      },
      deep: true,
    },
  },
};
</script>
