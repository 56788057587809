<template>
  <div>
    <Modal
      :title="titleModal"
      :width="widthModal"
      v-if="showModal('print')"
      :onHideModal="onHideModal"
    >
      <SelectOptionDocument
        module="PDV"
        :documentId="documentId"
        :rentId="rentId"
        v-show="!documentPreview"
        v-model="documentId"
        :clickedNext="nextStep"
      >
      </SelectOptionDocument>
      <DocumentPreview
        v-if="documentPreview"
        :documentId="documentId"
        :rentId="rentId"
        :back="back"
      />
    </Modal>
  </div>
</template>

<script>
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded";
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";

import SelectOptionDocument from "../../adm/document/SelectOptionDocument.vue";
import DocumentPreview from "../../adm/document/DocumentPreview.vue";

import { mapGetters, mapMutations } from "vuex";

export default {
  name: "PrintPdv",
  components: {
    Button,
    Molded,
    Modal,
    SelectOptionDocument,
    DocumentPreview,
  },
  props: {
    rentId: String,
    onHideModal: Function,
  },
  data() {
    return {
      documentPreview: false,
      documentId: "",
      titleModal: "Selecione o Documento",
      widthModal: 600,
    };
  },
  mounted() {
    this.openModal("print");
  },
  computed: {
    ...mapGetters("generic", ["showModal"]),
  },
  methods: {
    ...mapMutations("generic", ["openModal", "removeLoading"]),
    print() {
      this.openModal("print");
      this.removeLoading(["btnPrint"]);
    },
    nextStep() {
      this.documentPreview = true;
      this.titleModal = "Impressão";
      this.widthModal = 1200;
    },
    back() {
      this.documentPreview = false;
      this.titleModal = "Selecione o Documento";
      this.widthModal = 700;
      this.removeLoading(["back"]);
    },
  },
};
</script>
